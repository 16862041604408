import React from "react";
import propTypes from "prop-types";
import { useDynamicSVGImport } from "@utils";
import classNames from "classnames";

const Icon = React.memo(
  ({
    name,
    className: _className,
    encode,
    placeholder: Placeholder,
    fitHeight,
  }) => {
    const { SvgIcon, loading } = useDynamicSVGImport(name, { encode });
    return (
      <span
        className={classNames("icon block fill-current", _className, {
          "icon--fit-height": fitHeight,
        })}
      >
        {!encode && !loading && SvgIcon && <SvgIcon />}
        {encode && !loading && SvgIcon && (
          <img alt={name} className="w-full h-full block" src={SvgIcon} />
        )}
        {loading && !!Placeholder && <Placeholder />}
      </span>
    );
  }
);

Icon.defaultProps = {
  className: "w-16 h-16",
};

Icon.propTypes = {
  name: propTypes.string.isRequired,
  className: propTypes.string,
};

export default Icon;
