import React, { useRef, useState, useEffect } from "react";
import render from "preact-render-to-string";

const useDynamicSVGImport = (name, options = {}) => {
  const ImportedIconRef = useRef();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const { onCompleted, onError, encode } = options;

  useEffect(() => {
    setLoading(true);
    const importIcon = async () => {
      try {
        const Icon = (await import(`../svg/${name}.svg`)).default;
        ImportedIconRef.current = encode
          ? `data:image/svg+xml;base64,${window.btoa(
              render(<Icon />)
                .replace(/\r?\n|\r/g, "")
                .replace('data-reactroot=""', "")
                .replace(
                  "<svg",
                  '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"'
                )
            )}`
          : Icon;
        if (onCompleted) {
          onCompleted(name, ImportedIconRef.current);
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
        if (onError) {
          onError(err);
        }
        setError(err);
      } finally {
        // setLoading(false);
      }
    };
    importIcon();
  }, [name, onCompleted, onError]);

  return { error, loading, SvgIcon: ImportedIconRef.current };
};

export default useDynamicSVGImport;
