import React from "react";
import propTypes from "prop-types";
// import { Header } from "@organisms";
import { PageSizeObserver } from "@base";
import loadable from "@loadable/component";
import BrowserWarning from "../atoms/BrowserWarning";

const Helmet = loadable(() =>
  import("react-helmet").then(({ Helmet: c }) => c)
);

const DefaultLayout = ({ children, path }) => {
  // the layout
  return (
    <div id="app_layout" className="font-serif text-white bg-black">
      <PageSizeObserver />
      <Helmet>
        {/* uncomment to load typekit */}
        <link
          rel="preload"
          href={`https://use.typekit.net/${process.env.GATSBY_TYPEKIT}.css`}
          as="style"
        />
        <link
          rel="stylesheet"
          href={`https://use.typekit.net/${process.env.GATSBY_TYPEKIT}.css`}
        />
        {/* uncomment to allow social embeds */}
        {/* <script
          async
          defer
          crossOrigin="anonymous"
          src={`https://connect.facebook.net/en_US/sdk.js#xfbml=1&autoLogAppEvents=1&version=v7.0&appId=${process.env.GATSBY_FACEBOOK_APP_ID}`}
          nonce="12UgffVI"
        />
        <script
          async=""
          src="https://platform.twitter.com/widgets.js"
          charset="utf-8"
        />
        <script async src="https://www.instagram.com/embed.js" /> */}
      </Helmet>
      {/* <Header /> */}
      {children}
      {/* <BrowserWarning /> */}
      {/* uncomment if embedding facebook posts */}
      {/* <div id="fb-root" /> */}
    </div>
  );
};

DefaultLayout.propTypes = {
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]).isRequired,
};

export default DefaultLayout;
