import React, { useRef, useState } from "react";

import classNames from "classnames";
import { InView } from "react-intersection-observer";

const InViewWrapper = ({
  children,
  unobserveAfterEntry,
  observerOptions: _observerOptions,
  className,
  inViewClassName,
  style,
  inViewStyle,
  onEnter,
  onExit,
  threshold,
  rootMargin,
}) => {
  const el = useRef();

  const [visible, setVisible] = useState();

  const handleChange = (inView, entry) => {
    if (inView) {
      if (onEnter) {
        onEnter();
      }
      setVisible(true);
    } else {
      if (onExit) {
        onExit();
      }
      setVisible(false);
    }
  };

  return (
    <InView
      ref={el}
      threshold={threshold}
      rootMargin={rootMargin}
      className={classNames(className, {
        [inViewClassName]: visible,
      })}
      style={visible ? inViewStyle : style}
      triggerOnce={unobserveAfterEntry}
      onChange={handleChange}
    >
      {children}
    </InView>
  );
};

InViewWrapper.defaultProps = {
  allowDuringTransition: false,
  threshold: 0,
  rootMargin: "0px",
};

export default InViewWrapper;
